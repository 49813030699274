import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/wcenatus/Documents/GitHub/cwa-site/src/templates/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Card Brands recently announced that there will be some changes to certain interchange categories for April 2021 as well as some new fees to be added.  Visa is eliminating certain B2B rates and is   increasing rates on certain business Card Not Present Transactions.   Additionally, many Visa interchange rates will increase depending on a merchant’s MCC code or transaction size.  Visa is also introducing a transaction fee for any merchant that tries to charge a declined card more than fifteen (15) times in thirty (30) days.  Discover is implementing a five cents per transaction  program integrity fee on downgraded transactions.    In addition, Discover will be removing their MID Submission Level (MSL) interchange program and all transactions that qualified at these levels will see significant increases in interchange.  American Express will be increasing their assessment fee and raising their rates on foreign cards accepted at U.S. merchants.  As always, we here at CWA do our best to ensure that our merchant portfolio has a minimal impact when the Card Brands announce their  compliance updates.  Many additional changes by MasterCard and Visa have been postponed until  2022.  However, because of these current  changes you will see a small  increase on  your Mid-Qualified Rates of  0.0007  and your Non-Qualified Rates of 0.0014.   `}<strong parentName="p">{`Qualified Rates will not change`}</strong>{`.  We have tried to keep these unfortunate increases to as low as possible.   We strongly recommend that you visit  our website, `}<a parentName="p" {...{
        "href": "http://www.cwams.com"
      }}>{`www.cwams.com`}</a>{` under Industry News for compliance updates that are announced by Visa, MasterCard, Discover and American Express.  You also can visit the Card Brand sites to see a list of their interchange rates and other fees and expenses that they charge to merchants.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      